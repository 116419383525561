import React from 'react';

export default function Home() {
  return(      
    <div className="">
      <div className='container'>
        <h1>Prismaflora</h1>
        <h2>Gardener's helper</h2>
      </div>

      <div className="background-image background-image--home" />
    </div>
 
  );
}
