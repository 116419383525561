import React from 'react';

export default function Contact() {
  return (
    <div>
      <h2>Contact</h2>
      <ul className="container">
        <li className="">
          Denise Walter
        </li>
        <li className="">
          Melrose, MA
        </li>
        <li className="">
          <a href="mailto:denisewalter76@gmail.com">
            prismaflora2@gmail.com
          </a>  
        </li>
        <li className="">
          <a href="tel:508-498-1762">
            (781) 462-1903
          </a>
        </li>
      </ul>

      <div className="background-image background-image--contact" />
    </div>
  );
}
