// import logo from './logo.svg';
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/layout/background-image.scss';

function App() {
  return (
    <div className="App">
      <Navbar />
     
      <div className='container'>
        <BrowserRouter>
          <Routes>
            <Route path='/'>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/contact" element={<Contact/>} />
              <Route exact path="/about" element={<About/>} />
            </Route>
  
          </Routes>
         
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
