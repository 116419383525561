import React from 'react';

export default function About() {
  return (
    <div className="container">
      <h2>About</h2>
      <p>
        I am an avid gardener looking to assist other gardeners and homeowners with maintaining their landscapes. I know how hard it can be to keep up with garden tasks and would love to lend a hand to those in need.
      </p>
      <p>
        My gardening interests are working with native plants, permaculture, and pollinator gardens.
      </p>
      <p>
        I would love to help homeowners with garden tasks such as planting, weeding, and deadheading. Contact me if you could use some assistance with these garden chores!
      </p>

      <div className="background-image background-image--about" />
    </div>

  );
}
